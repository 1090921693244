import React, { useState, useEffect } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { GridRowModes } from "@mui/x-data-grid";
//UTIL
import { findObject, messageDisplay, responseErrors, responseSuccess } from "utils";
import { validationSearchForm } from "utils/validation";
// COMPONENT
import { InputButton } from "components/UI";
//Hooks
import useButtonMode from "../hooks/useButtonMode";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { useApmcDeleteMutation, useApmcSearchMutation } from "shared/services/inventory-forecast";
// TYPE
import { API_NAME_APMC, PAGINATION, ROW_PER_PAGE, BUTTON_VALUE, FIRST_PAGE } from "shared/constants";
import { ModeAction, MessageType, MSG_TYPE } from "state/enum";
import { userProfile } from "constant";
import { getFunctionIDTemplate } from "../hooks/utils";
import { transformDataApmc } from "../hooks/transfromData";
import { useSelector } from "react-redux";
export default function ButtonBar({
  form,
  setForm,
  rows,
  setRows,
  columns,
  mode,
  setMode,
  onSearch,
  setOnSearch,
  setDisable,
  dataList: { modeList, dataTypeList, impExpList },
  getSearch,
  setMsg: { setMsgAlert, setMsgError },
  pagination: { pagination, pageNumber, setPagination, setPageNumber },
  rowSelection: { rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel },
  loading: { startLoading, stopLoading },
  functionId,
  ...rest
}) {
  const userInfo = useSelector(state => state.auth.user);
  const confirmDialogCtx = useConfirmDialogContext();
  const apmcSearch = useApmcSearchMutation();
  const apmcDelete = useApmcDeleteMutation();
  // Button
  const [searchButton, setSearchButton] = useState(false);
  const [clearButton, setClearButton] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [deleteButton, setDeleteButton] = useState(true);
  useEffect(() => {
    if (!rows?.length) {
      setAddButton(false);
      setEditButton(true);
      setDeleteButton(true);
    }
  }, [rows]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useButtonMode({
      mode,
      stateButton: {
        setSearchButton,
        setClearButton,
        setAddButton,
        setEditButton,
        setDeleteButton,
      },
    });
  }, [mode]);
  // ? Handle Button
  const handleSearch = async e => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      // Reset Data of Table
      setOnSearch(false);
      setDisable(false);
      setMode(ModeAction.VIEW);
      setRows([]);
      setPagination({});
      setPageNumber(FIRST_PAGE);
      setRowSelectionModel([]);
      setRowModesModel({});
      setForm(old => ({
        ...old,
        [API_NAME_APMC.FILE]: {},
        [API_NAME_APMC.ORG_FILE_NAME]: "",
      }));

      const bodySearch = {
        [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner,
        [API_NAME_APMC.COMPANY_ABBR]: userProfile.dataOwner,
        [API_NAME_APMC.FORECAST_MONTH]: form?.forecastMonth,
        [API_NAME_APMC.MODE]: form?.mode,
        [API_NAME_APMC.DATA_TYPE_CD]: form?.dataTypeCd,
        [API_NAME_APMC.UPLOAD_STATUS_CD]: form?.uploadStatusCd,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };

      const { isSuccess, errors } = validationSearchForm({
        data: bodySearch,
        rule: [
          {
            field: API_NAME_APMC.FORECAST_MONTH,
            type: MessageType.EMPTY,
            key: ["Forecast Month"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      // ? SET BODY FOR SEARCH
      startLoading();
      const searchData = await apmcSearch(bodySearch);
      stopLoading();
      // ? TRANSFORM DATA
      const data = transformDataApmc(searchData, pageNumber, ROW_PER_PAGE);
      // ? CHECK DATA NOT FOUND

      if (!data?.length) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MSTD0059AERR",
          msg: [""],
        });
        setMsgError([msg]);
        return;
      }

      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClear = () => {
    setMsgError([]);
    setMsgAlert([]);

    // reset form
    setForm({
      [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner,
      [API_NAME_APMC.COMPANY_ABBR]: userProfile.dataOwner,
      [API_NAME_APMC.FORECAST_MONTH]: "",
      [API_NAME_APMC.MODE]: "",
      [API_NAME_APMC.DATA_TYPE_CD]: "",
      [API_NAME_APMC.UPLOAD_STATUS_CD]: "",
      [API_NAME_APMC.ORG_FILE_NAME]: "",
      [API_NAME_APMC.FILE]: {},
      [API_NAME_APMC.IMP_EXP_CD]: "",
      [API_NAME_APMC.USER_ID]: userInfo.userName,
    });
    setDisable(false);
    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setRows([]);
    setRowSelectionModel([]);
    setRowModesModel({});
    setPagination({});
    setPageNumber(1);
    document.getElementById("input-apmc-month").focus();
  };
  const handleAdd = () => {
    setDisable(false);
    setForm(old => ({
      ...old,
      [API_NAME_APMC.FILE]: {},
      [API_NAME_APMC.ORG_FILE_NAME]: "",
    }));
    let maxNo = -1;
    setMode(ModeAction.ADD);
    setMsgError([]);
    setMsgAlert([]);
    setOnSearch(true);
    const field = {};
    columns.forEach(column => {
      if (column.field === "rowNumber") {
        maxNo = !rows || rows.length === 0 ? 1 : Math.max(...rows.map(item => item.rowNumber));
        field[column.field] = rows.length ? maxNo + 1 : maxNo;
      } else {
        field[column.field] = "";
      }
    });
    setRows(prev => [
      ...prev,
      {
        ...field,
        isNew: true,
      },
    ]);

    const maxNoModel = rows.length ? maxNo + 1 : maxNo;

    setRowModesModel(oldModel => ({
      ...oldModel,
      [maxNoModel]: { mode: GridRowModes.Edit },
    }));

    setRowSelectionModel([maxNoModel]);
  };
  const handleEdit = () => {
    setMsgError([]);
    setMsgAlert([]);
    setMode(ModeAction.EDIT);
    setForm(old => ({
      ...old,
      [API_NAME_APMC.FILE]: {},
      [API_NAME_APMC.ORG_FILE_NAME]: "",
    }));
    let tempRowModesModel = rowModesModel;

    const rowSelect = rowSelectionModel.map(val => {
      return (tempRowModesModel = {
        ...tempRowModesModel,
        [val]: { mode: GridRowModes.Edit },
      });
    });

    if (rowSelect) {
      setRowModesModel(tempRowModesModel);
    }
  };

  const handleDelete = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({ type: "confirmDelete" });
    if (!confirm) {
      return;
    }
    try {
      if (!rowSelectionModel.length) {
        const msg = messageDisplay({
          type: MSG_TYPE.ERROR,
          code: "MDN90024AERR",
          msg: [""],
        });
        setMsgError([msg]);
        return;
      }
      const selectedNo = rowSelectionModel[0];
      const row = rows.find(v => v.rowNumber === selectedNo);
      const functionIdOfDataType = getFunctionIDTemplate(
        findObject({
          data: dataTypeList,
          value: row?.dataType,
          property: "value",
          field: "cd",
        })
      );
      const body = {
        [API_NAME_APMC.DATA_OWNER]: row?.dataOwner,
        [API_NAME_APMC.UPDATE_BY]: userInfo.userName,
        [API_NAME_APMC.IMP_EXP_CD]: row?.uploadImpExpCd
          ? findObject({
              data: impExpList,
              value: row?.uploadImpExpCd,
              property: "impExpCd",
              field: "companyAbbr",
            })
          : "",
        [API_NAME_APMC.MODE]: row?.mode,
        [API_NAME_APMC.FORECAST_MONTH]: row?.forecastMonth,
        [API_NAME_APMC.DATA_TYPE_CD]: row?.dataType
          ? findObject({
              data: dataTypeList,
              value: row?.dataType,
              property: "value",
              field: "cd",
            })
          : "",
        [API_NAME_APMC.DATA_TYPE]: row?.dataType,
        [API_NAME_APMC.MANUAL_UPLOAD_INVOICE_FLAG]: "Y",
        [API_NAME_APMC.FUNCTION_ID]: functionIdOfDataType,
      };

      const fileUploadId = row.fileUploadId;
      startLoading();
      const response = await apmcDelete(fileUploadId, body);
      stopLoading();
      const msgDelete = responseSuccess(response);
      setMsgAlert([msgDelete]);

      //Search new data
      const bodySearch = {
        [API_NAME_APMC.DATA_OWNER]: userProfile.dataOwner,
        [API_NAME_APMC.COMPANY_ABBR]: userProfile.dataOwner,
        [API_NAME_APMC.FORECAST_MONTH]: form?.forecastMonth,
        [API_NAME_APMC.MODE]: form?.mode,
        [API_NAME_APMC.DATA_TYPE_CD]: form?.dataTypeCd,
        [API_NAME_APMC.UPLOAD_STATUS_CD]: form?.uploadStatusCd,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { searchData, data } = await getSearch(bodySearch);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);
      setRowModesModel({});
      if (!data.length) {
        setMode(ModeAction.VIEW);
        setOnSearch(false);
        setRowModesModel({});
        setRowSelectionModel([]);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setRows([]);
        return false;
      }

      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
      window.scrollTo(0, 0);
      return;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };

  return (
    <Box sx={{ padding: "1rem" }}>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_search"
            data-testid="btn_search"
            value={BUTTON_VALUE.SEARCH}
            disabled={searchButton}
            onClick={handleSearch}
            name={`${functionId}Search`}
          />
          <InputButton
            id="btn_clear"
            value={BUTTON_VALUE.CLEAR}
            disabled={clearButton}
            onClick={handleClear}
            name={`${functionId}Clear`}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <InputButton
            id="btn_add"
            value={BUTTON_VALUE.ADD}
            onClick={handleAdd}
            disabled={addButton}
            onKeyDown={e => {
              if (!onSearch && e.key === "Tab") {
                e?.preventDefault();
                document.getElementById("input-apmc-month").focus();
              }
            }}
            name={`${functionId}Add`}
          />
          <InputButton
            id="btn_edit"
            value={BUTTON_VALUE.EDIT}
            onClick={handleEdit}
            disabled={editButton}
            name={`${functionId}Edit`}
          />
          <InputButton
            id="btn_delete"
            value={BUTTON_VALUE.DELETE}
            onClick={handleDelete}
            disabled={deleteButton}
            name={`${functionId}Delete`}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
