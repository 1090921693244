import React from "react";
// Utils
import { responseErrors, messageDisplay } from "utils";
import { validationSearchForm } from "utils/validation";
//Components
import { Grid, Divider } from "@mui/material";
import { InputButton } from "components/UI";
//Constant
import { ModeAction, MSG_TYPE, MessageType } from "state/enum";
import {
  API_NAME_ON_DEMAND_EXCEL,
  PAGINATION,
  BUTTON_VALUE,
  ROW_PER_PAGE,
  FIRST_PAGE,
  COMMON_ENUM,
} from "shared/constants";
import { userProfile } from "constant";

//Service
import { useOnDemandExcelSearchMutation } from "shared/services/common/monitoring";
import dayjs from "dayjs";
import { useLoadingContext } from "shared/contexts/LoadingContext";
import { useSelector } from "react-redux";
export default function ButtonBar(props) {
  const {
    form,
    setForm,
    setRows,
    setMode,
    onSearch,
    setOnSearch,
    setRowModesModel,
    setRowSelectionModel,
    pagination: { pageNumber, setPageNumber, setPagination },
    setMsg: { setMsgError, setMsgAlert },
    functionId,
  } = props;
  const { startLoading, stopLoading } = useLoadingContext();
  const dateTime = useSelector(state => state.common.dateTime);

  const searchOnDemand = useOnDemandExcelSearchMutation();
  const handleSearchBtn = async e => {
    try {
      e.preventDefault();
      setMsgError([]);
      setMsgAlert([]);
      setMode(ModeAction.VIEW);
      setRows([]);
      setPageNumber(FIRST_PAGE);
      setPagination({});
      setOnSearch(false);
      setRowSelectionModel([]);
      setRowModesModel({});
      const body = {
        [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: form?.dataOwner,
        [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: form?.requestDate,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: form?.reportStatus,
        [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: form?.reportName,
        [API_NAME_ON_DEMAND_EXCEL.USER_ID]: form?.userId,
        [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: form?.orderByDate,
        [PAGINATION.PAGE_NUMBER]: pageNumber,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      const { isSuccess, errors } = validationSearchForm({
        data: body,
        rule: [
          {
            field: API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE,
            type: MessageType.EMPTY,
            key: ["Request Date"],
          },
        ],
      });
      if (!isSuccess) {
        setMsgError(errors);
        window.scrollTo(0, 0);
        return;
      }
      startLoading();
      const searchData = await searchOnDemand(body);
      stopLoading();
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_NAME_ON_DEMAND_EXCEL.ROW_NUMBER]: item?.rowNumber,
          [API_NAME_ON_DEMAND_EXCEL.DOC_ID]: item?.docId,
          [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: item?.reportName,
          [API_NAME_ON_DEMAND_EXCEL.FILE_NO]: item?.fileNo,
          [API_NAME_ON_DEMAND_EXCEL.FILE_COUNT]: item?.fileCnt,
          [API_NAME_ON_DEMAND_EXCEL.NO_OF_ZIP_FILE]: item?.noOfZipFile,
          [API_NAME_ON_DEMAND_EXCEL.FILE_NAME]: item?.fileName,
          [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: item?.requestDt,
          [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: item?.reportStatus,
          [API_NAME_ON_DEMAND_EXCEL.STATUS_ID]: item?.status,
          [API_NAME_ON_DEMAND_EXCEL.GENERATED_BY]: item?.generatedBy,
          [API_NAME_ON_DEMAND_EXCEL.FILE_SIZE]: item?.fileSize,
          [API_NAME_ON_DEMAND_EXCEL.FILE_STATUS]: item?.fileStatus,
          [API_NAME_ON_DEMAND_EXCEL.OVERRIDE_PATH]: item?.overridePath,
        })) ?? [];

      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: [""] })]);
        return;
      }
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
      setOnSearch(true);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      return;
    }
  };
  const handleClearBtn = async () => {
    setMode(ModeAction.VIEW);
    setOnSearch(false);
    setMsgError([]);
    setMsgAlert([]);
    setRowSelectionModel([]);
    setRowModesModel({});
    // reset form
    setForm({
      [API_NAME_ON_DEMAND_EXCEL.REQUEST_DATE]: dayjs(dateTime, COMMON_ENUM.DATETIME_FORMAT).format("DD/MM/YYYY"),
      [API_NAME_ON_DEMAND_EXCEL.REPORT_STATUS]: "",
      [API_NAME_ON_DEMAND_EXCEL.REPORT_NAME]: "",
      [API_NAME_ON_DEMAND_EXCEL.DATA_OWNER]: userProfile?.dataOwner,
      [API_NAME_ON_DEMAND_EXCEL.USER_ID]: form?.userId,
      [API_NAME_ON_DEMAND_EXCEL.ORDER_BY_DATE]: true,
      [API_NAME_ON_DEMAND_EXCEL.DOC_ID]: "",
    });
    setRows([]);
    setPagination({});
    setPageNumber(FIRST_PAGE);
    document.getElementById("select_request_date").focus();
  };
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}>
        <InputButton
          id="btn_search"
          value={BUTTON_VALUE.SEARCH}
          disabled={false}
          onClick={handleSearchBtn}
          name={`${functionId}Search`}
        />
        <InputButton
          id="btn_clear"
          value={BUTTON_VALUE.CLEAR}
          disabled={false}
          onClick={handleClearBtn}
          onKeyDown={e => {
            if (!onSearch && e.key === "Tab") {
              e.preventDefault();
              document.getElementById("select_request_date").focus();
            }
          }}
          name={`${functionId}Clear`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
}
